import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./landing/Landing";
import RootLayout from "./root-layout/RootLayout";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import AboutMain from "./landing/pages/about/AboutMain";
import ServicesMain from "./landing/pages/serivces/ServicesMain";
import ContactMain from "./landing/pages/contact/ContactMain";
import ShopMain from "./landing/pages/shop/ShopMain";
import Products from "./landing/pages/products/MainProducts";
import ProductPage from "./landing/pages/shop/product-page/ProductPage";
import { ShoppingCartProvider } from "./UserShoppingContext";

library.add(fas, fab, far);
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="about" element={<AboutMain />} />
      <Route path="services" element={<ServicesMain />} />
      <Route path="contact" element={<ContactMain />} />
      <Route path="shop" element={<ShopMain />} />
      <Route path="shop/products" element={<Products />} />
      <Route path="/shop/products/:id" element={<ProductPage />} />
      <Route path="*" element={<Home />} />
    </Route>
  )
);
function App() {
  return (
    <ShoppingCartProvider>
      <RouterProvider router={router} />
    </ShoppingCartProvider>
  );
}

export default App;
