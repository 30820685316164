import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
export default function ProductPageDetails(props) {
  const [activeProductDetails, setActiveProductDetails] = useState(0);
  const productMoreDetails = props.moreDetails;
  return (
    <section className="w-full max-w-[1140px] mx-auto px-4 lg:px-3">
      <div>
        <div className="flex justify-center border-t">
          <button
            className={`text-center w-[50%] py-1 font-medium ${
              activeProductDetails === 0 ? "border-t-2 border-[#74a84a]" : null
            }`}
            onClick={() => setActiveProductDetails(0)}
          >
            Description
          </button>
          <button
            className={`text-center w-[50%] py-1 font-medium ${
              activeProductDetails === 1 ? "border-t-2 border-[#74a84a]" : null
            }`}
            onClick={() => setActiveProductDetails(1)}
          >
            Reviews (0)
          </button>
        </div>
        <div className="my-5">
          <div className={`${activeProductDetails === 0 ? "block" : "hidden"}`}>
            {productMoreDetails.map((detail, index) => (
              <div key={index} className="py-2">
                <p className="font-medium text-lg">
                  Paper-type: {detail.title}
                </p>
                <ul className="list-disc list-inside text-[#585858] pt-1">
                  {detail.listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
            <p className="text-[#585858] my-3">
              <span className="font-medium text-[#000]">Note:</span> There may
              be a slight difference in actual color, due to the colors of
              display.
            </p>
          </div>
          <div
            className={`text-[#585858] ${
              activeProductDetails === 1 ? "block" : "hidden"
            }`}
          >
            <p>There are no reviews yet.</p>
            <div className="border rounded p-3 md:p-5">
              <h3 className="text-2xl">Be the first to review “Postcard V1”</h3>
              <span>
                Your email address will not be published. Required fields are
                marked *
              </span>

              <form className="flex flex-col my-4 gap-4">
                <div className="flex gap-2 flex-wrap">
                  <p className="text-lg">Your rating*</p>
                  <StyledRating
                    name="customized-color"
                    defaultValue={0}
                    getLabelText={(value) =>
                      `${value} Heart${value !== 1 ? "s" : ""}`
                    }
                    precision={0.5}
                    icon={<FavoriteIcon fontSize="inherit" />}
                    emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                  />
                </div>
                <div>
                  <label htmlFor="review-text" className="text-lg">
                    Your review*
                  </label>
                  <textarea
                    name=""
                    id="review-text"
                    cols="30"
                    rows="4"
                    className="rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3 block w-full"
                    minLength="10"
                    required
                  ></textarea>
                </div>
                <div className="flex flex-col md:flex-row item-center justify-between gap-10">
                  <div className="basis-1/2">
                    <label htmlFor="review-name">Name*</label>
                    <input
                      type="text"
                      id="review-name"
                      className="rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3 block w-full"
                      required
                    />
                  </div>
                  <div className="basis-1/2">
                    <label htmlFor="review-email">Email*</label>
                    <input
                      type="email"
                      name=""
                      id="review-email"
                      className="rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3 block w-full"
                      required
                    />
                  </div>
                </div>
                <div>
                  <input type="checkbox" name="" id="review-save-inputs " />
                  <label htmlFor="review-save-inputs" className="px-2">
                    Save my name, email, and website in this browser for the
                    next time I comment.
                  </label>
                </div>
                <input
                  type="submit"
                  value="Send Now"
                  className="text-white rounded bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-widest py-2 px-8 mt-6 md:text-lg w-48"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
