import React from "react";

export default function LandingServices() {
  let services = [
    {
      id: 1,
      title: "Web Design",
      description:
        "Custom web design for small businesses, we help you capture new audiences and increase your sales.",
      img: "./img/Service-1-min.jpg",
    },
    {
      id: 2,
      title: "Graphic Design",
      description:
        "Logos, merchandise and more. Anyone can create nice graphics. We think it’s better to create memorable ones.",
      img: "./img/Service-2-min.jpg",
    },
    {
      id: 3,
      title: "Content Creation",
      description:
        "Want to attract people to your website?  You have to have the best content in the world. That’s what we do.",
      img: "./img/Service-3-min.jpg",
    },
  ];
  return (
    <section className="px-5 py-16 mx-auto">
      <div className="flex flex-col gap-5 md:flex-row md:justify-between max-w-[1140px] mx-auto lg:gap-8">
        {services.map((item) => (
          <div key={item.id}>
            <img
              src={item.img}
              alt={item.title}
              className="rounded align-middle w-full min-h-[250px] h-full md:max-h-[350px] lg:max-h-[450px]"
            />
            <h3 className="text-2xl font-semibold pt-6">{item.title}</h3>
            <p className="pt-2 text-[#585858]">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
