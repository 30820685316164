import React from "react";
import LandingHeader from "./header/LandingHeader";
import LandingServices from "./about-services/LandingServices";
import LandingAbout from "./about-us/LandingAbout";
import LandingCounseling from "./talk-to-us/LandingCounseling";
import LandingFooter from "./footer/LandingFooter";

export default function Home() {
  return (
    <>
      <LandingHeader />
      <LandingServices />
      <LandingAbout />
      <LandingCounseling />
      <LandingFooter />
    </>
  );
}
