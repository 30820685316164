import React from "react";
import productJson from "../../../../products.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import ProductBox from "../ProductBox";

export default function RelatedProducts(props) {
  const shopProducts = JSON.parse(JSON.stringify(productJson));

  const relatedProduct = shopProducts.filter(
    (product) => product.category === props.category
  );

  return (
    <section className="w-full max-w-[1140px] mx-auto my-16 px-4">
      <h3 className="text-3xl pb-6 font-medium">Related Product</h3>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {relatedProduct.map((product) => (
          <SwiperSlide key={product.id}>
            <ProductBox {...product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
