import React from "react";
import ShopHeader from "./header/ShopHeader";
import PrevMainProducts from "./main-page-Products/PrevMainProducts";
import CustomersSay from "./customer-say/CustomersSay";
import ShopSuggestion from "./suggestion/ShopSuggestion";
import ShopPromotes from "./promotes/ShopPromotes";
import LandingFooter from "../../footer/LandingFooter";
export default function ShopMain() {
  return (
    <>
      <ShopHeader />
      <PrevMainProducts />
      <CustomersSay />
      <ShopSuggestion />
      <ShopPromotes />
      <LandingFooter />

    </>
  );
}
