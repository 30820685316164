import React from "react";
import { Link } from "react-router-dom";

export default function ShopHeader() {
  return (
    <header className="bg-header-banner w-full h-[520px] md:h-[650px] lg:h-[90vh] bg-cover bg-center">
      <div className="text-center w-full pt-40 md:pt-56 lg:pt-64">
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-medium text-[#2c541d] tracking-wide">
          EARTH
        </h1>
        <p className="tracking-widest	text-2xl md:text-3xl lg:text-4xl font-light mt-2">
          MULTIPURPOSE STORE
        </p>
        <Link to="products">
          <button className="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-10 mt-6 md:text-lg">
            SHOP NOW
          </button>
        </Link>
      </div>
    </header>
  );
}
