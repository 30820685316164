import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function CustomersSay() {
  let customersQuote = [
    {
      id: 1,
      name: "JENNIFER LEWIS",
      quote:
        "Fast shipping and excellent customer service. The product was even better than expected. I will definitely be a returning customer.",
      img: "./img/pages/customer-img (1).jpeg",
    },
    {
      id: 2,
      name: "ALICIA HEART",
      quote:
        "Great user experience on your website. I found exactly what I was looking for at a great price. I will definitely be telling my friends.",
      img: "./img/pages/customer-img (3).jpeg",
    },
    {
      id: 3,
      name: "JUAN CARLOS",
      quote:
        "Thank you for the excellent shopping experience. It arrived quickly and was exactly as described. I will definitely be shopping with you again in the future.",
      img: "./img/pages/customer-img (2).jpeg",
    },
  ];
  return (
    <section className="border-t">
      <div className="max-w-[1140px] mx-auto my-10 md:my-16 px-3">
        <div className="pb-10">
          <h2 className="text-2xl font-medium md:text-4xl">
            What Our Customers Say
          </h2>
        </div>
        <div className="flex flex-col gap-10 md:flex-row md:gap-14 lg:gap-24">
          {customersQuote.map((customer) => (
            <div key={customer.id}>
              <FontAwesomeIcon
                icon="fa-solid fa-quote-left"
                className="text-[#2c541d] text-3xl md:text-4xl"
              />
              <blockquote className="py-6 text-[#585858]">
                {customer.quote}
              </blockquote>
              <img
                src={customer.img}
                alt={customer.name}
                className="rounded-full mb-4"
              />
              <strong className="font-medium text-sm">{customer.name}</strong>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
