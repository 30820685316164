import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ContactInfo() {
  return (
    <div className="px-3 py-5 basis-2/4">
      <h3 className="text-center text-3xl font-medium md:text-4xl">
        Talk To Us
      </h3>
      <div className="mt-5">
        <div className="py-5 md:py-6 md:flex gap-5">
          <div className="w-10 h-10 flex items-center justify-center text-xl border border-[#74a84a] rounded-full inline-block">
            <FontAwesomeIcon icon="fa-solid fa-envelope" />
          </div>
          <div className="py-4 md:py-0">
            <h4 className="text-xl text-[#585858] font-light">Email</h4>
            <a
              href="mailto:dev.sepehr@outlook.com"
              className="text-[#2c541d] text-2xl"
            >
              dev.sepehr@outlook.com
            </a>
          </div>
        </div>
        <div className="py-5 md:py-6 md:flex gap-5">
          <div className="w-10 h-10 flex items-center justify-center text-xl border border-[#74a84a] rounded-full inline-block">
            <FontAwesomeIcon icon="fa-solid fa-phone" />
          </div>
          <div className="py-4 md:py-0">
            <h4 className="text-xl text-[#585858] font-light">Phone Number</h4>
            <a href="tel:+202-555-0188" className="text-[#2c541d] text-2xl">
              202-555-0188
            </a>
          </div>
        </div>
        <div className="py-5 md:py-6 md:flex gap-5">
          <div className="w-10 h-10 flex items-center justify-center text-xl border border-[#74a84a] rounded-full inline-block">
            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
          </div>

          <div className="py-4 md:py-0">
            <h4 className="text-xl text-[#585858] font-light">Address</h4>
            <p className="text-[#2c541d] text-2xl">
              2727 Ocean Road,
              <br /> Malibu, CA, 90264
            </p>
          </div>
        </div>
        <div className="text-center md:text-left md:pl-16">
          <h3 className="text-xl font-medium">Follow Us:</h3>
          <div className="flex justify-center gap-2 py-3 md:justify-start">
            <div className="bg-[#74a84a] inline-block w-10 h-10 flex items-center justify-center rounded-full text-white">
              <FontAwesomeIcon icon="fa-brands fa-discord" className="" />
            </div>
            <div className="bg-[#74a84a] inline-block w-10 h-10 flex items-center justify-center rounded-full text-white">
              <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="" />
            </div>
            <div className="bg-[#74a84a] inline-block w-10 h-10 flex items-center justify-center rounded-full text-white">
              <FontAwesomeIcon icon="fa-brands fa-linkedin-in" className="" />
            </div>
            <div className="bg-[#74a84a] inline-block w-10 h-10 flex items-center justify-center rounded-full text-white">
              <FontAwesomeIcon icon="fa-brands fa-google" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
