import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserShoppingCartContext from "../../../UserShoppingContext";
export default function ProductBox(props) {
  const { addToCart } = useContext(UserShoppingCartContext);

  return (
    <>
      <div className="h-[250px] overflow-hidden rounded group relative">
        <Link to={`/Shop/products/${props.id}`}>
          <img
            src={props.img}
            alt={props.title}
            className="h-full w-full object-center object-cover"
          />
        </Link>
        <span
          className="absolute top-2 right-2 bg-white text-[#74a84a] rounded-md hidden group-hover:block cursor-pointer opacity-80 hover:opacity-100"
          title="Add to cart"
          onClick={() => addToCart({ ...props, count: 1 })}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-bag-shopping"
            className="px-2 py-1 text-xl"
          />
        </span>
      </div>
      <span className="text-slate-400 pt-2 block">Poster</span>
      <Link to={`/Shop/products/${props.id}`}>
        <h2 className="text-lg font-medium py-1">{props.title}</h2>
      </Link>
      <strong className="text-md font-medium">${props.price}</strong>
    </>
  );
}
