import React from "react";
import products from "../../../../products";
import ProductBox from "../ProductBox";
export default function PrevMainProducts() {
  const getThreeProduct = [...products.slice(1, 4)];
  return (
    <section className="max-w-[1140px] mx-auto my-10 md:my-16">
      <div className="flex flex-col px-4 gap-10 min-[520px]:flex-row min-[520px]:gap-4 md:gap-6">
        {getThreeProduct.map((product) => (
          <div key={product.id} className="relative">
            <ProductBox {...product} />
          </div>
        ))}
      </div>
    </section>
  );
}