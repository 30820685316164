import React from "react";

export default function LandingCounseling() {
  return (
    <section className="bg-talk-to-us w-full bg-cover bg-center my-10">
      <div className="max-w-[575px] text-center mx-auto py-28 md:py-36">
        <h2 className="text-4xl md:text-5xl font-medium text-[#2c541d] tracking-[0.15em]">
          TALK TO US
        </h2>
        <p className="py-5 text-[#585858]">
          Have any questions? We are always open to talk about your business,
          new projects, creative opportunities and how we can help you.
        </p>
        <button className="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-8 mt-6 md:text-lg">
          GET IN TOUCH
        </button>
      </div>
    </section>
  );
}
