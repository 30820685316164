import { createContext, useEffect, useState } from "react";
import { addNotifToDom } from "./notifications/Notifications";
const UserShoppingCartContext = createContext();

export function ShoppingCartProvider({ children }) {
  const [userCart, setUserCart] = useState(
    () => JSON.parse(localStorage.getItem("userCart")) || []
  );

  const [userProductsCount, setUserProductsCount] = useState(0);
  const [userProductsPrice, setUserProductsPrice] = useState(0);
  let addToCart = (product) => {
    if (userProductsCount > 30 || userProductsCount + product.count > 30) {
      addNotifToDom("Sry, can't have more than 30 product in cart", "warning");
      return;
    }

    let userShoppingCart = [...userCart];

    let findProductIndex = userShoppingCart.findIndex(
      (item) => item.id === product.id
    );
    if (findProductIndex !== -1) {
      userShoppingCart[findProductIndex].count += product.count;
      setUserCart(userShoppingCart);
    } else {
      setUserCart((prevState) => [...prevState, product]);
    }
    addNotifToDom("Product added to shopping cart", "success");
  };

  let deleteCartProduct = (productId) => {
    let userShoppingCart = [...userCart];
    let findProductIndex = userShoppingCart.findIndex(
      (item) => item.id === productId
    );

    userShoppingCart.splice(findProductIndex, 1);
    setUserCart([...userShoppingCart]);
    console.log(userCart);
  };

  let calcUserProductPrice = () => {
    let totalPrice = 0;
    let shoppingCart = [...userCart];
    shoppingCart.forEach((item) => {
      totalPrice += item.price * item.count;
    });
    console.log(totalPrice.toFixed(2));
    setUserProductsPrice(totalPrice.toFixed(2));
  };

  useEffect(() => {
    let count = userCart.reduce((total, item) => total + item.count, 0);
    setUserProductsCount(count);
    localStorage.setItem("userCart", JSON.stringify(userCart));
    calcUserProductPrice();
  }, [userCart]);

  return (
    <UserShoppingCartContext.Provider
      value={{
        userCart,
        addToCart,
        userProductsCount,
        deleteCartProduct,
        userProductsPrice,
      }}
    >
      {children}
    </UserShoppingCartContext.Provider>
  );
}

export default UserShoppingCartContext;
