import React from "react";

export default function ContactForm() {
  return (
    <div className="px-3 py-5 basis-2/4">
      <h3 className="text-center text-3xl font-medium md:text-4xl">
        Get In Touch
      </h3>
      <form className="flex flex-col gap-3 p-5 shadow-md rounded-4 mt-5">
        <input
          type="text"
          className="bg-[#FAFAFA] rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3"
          placeholder="Your Name"
          required
        />
        <input
          type="tel"
          className="bg-[#FAFAFA] rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3"
          placeholder="Phone Number"
          required
        />
        <input
          type="email"
          className="bg-[#FAFAFA] rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3"
          placeholder="Email Address"
          required
        />
        <textarea
          cols="auto"
          rows="5"
          className="bg-[#FAFAFA] rounded border shadow-inner smooth-transition hover:shadow-none py-3 px-3"
          required
          placeholder="Your Message Here..."
        ></textarea>
        <input
          type="submit"
          value="Send Now"
          className="text-white rounded bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-widest py-2 px-8 mt-6 md:text-lg w-48"
        />
      </form>
    </div>
  );
}
