import React from "react";

export default function LandingAbout() {
  return (
    <section className="max-w-[1140px] mx-auto my-10">
      <div className="px-3 flex flex-col md:flex-row items-center md:gap-10 lg:gap-20">
        <div className="basis-2/4">
          <h2 className="tracking-widest text-3xl md:text-4xl font-medium py-5">
            ABOUT US
          </h2>
          <p className="text-[#585858]">
            I made it so it is super easy to update and so that it flows
            perfectly with my tutorials. Lots of love and hundreds of hours went
            into making it. I hope you love it as much as I do. I wish you the
            best of luck with your business, enjoy the adventure.
          </p>
        </div>
        <div className="py-6 basis-2/4">
          <img src="./img/about-us-img.jpg" alt="About Us" />
        </div>
      </div>
    </section>
  );
}
