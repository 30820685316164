import React from "react";
import AboutHeader from "./header/AboutHeader";
import AboutMission from "./about-us-main/AboutMission";
import AboutCounseling from "./about-counseling/AboutCounseling";
import LandingFooter from "../../footer/LandingFooter";

export default function AboutMain() {
  return (
    <>
      <AboutHeader />
      <AboutMission />
      <AboutCounseling />
      <LandingFooter />
    </>
  );
}
