import React, { useState } from "react";
import { Link } from "react-router-dom";
import productJson from "../../../products.json";
import ProductBox from "../shop/ProductBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "@mui/material/Slider";
import LandingFooter from "../../footer/LandingFooter";

export default function Products() {
  // let test = JSON.parse(JSON.stringify(productJson));
  const [shopProducts, setShopProducts] = useState(
    JSON.parse(JSON.stringify(productJson))
  );
  const [value, setValue] = useState([20, 37]);
  let pageLocation = window.location.pathname.split("/");
  function valueText(value) {
    return `${value}°C`;
  }
  const priceSlider = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="py-24 lg:pt-24 lg:pb-16 border-b mb-8">
        <div className="border-t py-8 lg:py-0 lg:pt-8">
          <div className="w-full max-w-[1140px] mx-auto lg:flex lg:flex-row-reverse">
            <div className="w-full lg:pl-5 my-5">
              <div>
                <div className="px-3">
                  <span className="text-sm block text-[#585858]">
                    <Link to="/">Home</Link> /{" "}
                    <Link to={`/${pageLocation[1]}`}>{pageLocation[1]}</Link> /{" "}
                    <Link to={`/${pageLocation[1]}/${pageLocation[2]}`}>
                      {pageLocation[2]}
                    </Link>
                  </span>
                  <h2 className="text-5xl text-[#2c541d] py-5">Shop</h2>
                  <div className="md:flex md:justify-between md:items-center">
                    {" "}
                    <p>Showing all {shopProducts.length} results</p>
                    <select className="py-2 my-2 border" defaultValue="1">
                      <option value="1">Default sorting</option>
                      <option value="2">Sort by popularity</option>
                      <option value="3">Sort by average rating</option>
                      <option value="4">Sort by price: low to high</option>
                      <option value="5">Sort by price: high to low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col my-5 px-3 gap-10 min-[520px]:flex-row min-[520px]:gap-0 min-[520px]:flex-wrap">
                  {shopProducts.map((product) => (
                    <div
                      key={product.id}
                      className="relative min-[520px]:basis-2/4 min-[520px]:px-3 md:px-2 md:basis-1/3 my-4"
                    >
                      <ProductBox {...product} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-[400px] lg:border-r px-5 my-5 ">
              <div>
                <div className="flex items-center justify-center gap-1">
                  <input
                    type="text"
                    className="bg-[#FAFAFA] rounded border shadow-inner smooth-transition hover:shadow-none py-2 px-3 w-full"
                    placeholder="Search products..."
                  />
                  <button className="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-4 md:text-lg rounded">
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                  </button>
                </div>
                <div className="my-6">
                  <h3 className="text-[#2c541d] text-xl font-medium">
                    Filter by price
                  </h3>
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    onChange={priceSlider}
                    valueLabelDisplay="auto"
                    getAriaValueText={valueText}
                    className="my-2"
                  />
                </div>
                <div>
                  <h3 className="text-[#2c541d] text-xl font-medium">
                    Categories
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LandingFooter />
    </>
  );
}
