import React from "react";
import { Link } from "react-router-dom";

export default function ShopSuggestion() {
  return (
    <section className="bg-services-page-banner w-full bg-cover bg-center my-10">
      <div className=" text-center mx-auto py-28">
        <h2 className="text-4xl md:text-5xl font-medium text-[#2c541d] tracking-widest">
          Give the Gift of a Postcard
        </h2>
        <p className="py-5 text-[#585858]">
          Give the gift of a lasting memory with a postcard
        </p>
        <Link to="products">
          <button className="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-8 mt-6 md:text-lg">
            PURCHASE A POSTCARD
          </button>
        </Link>
      </div>
    </section>
  );
}
