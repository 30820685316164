import React, { useContext, useState } from "react";
import { Navigate, useParams } from "react-router";
import productJson from "../../../../products.json";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductPageDetails from "./ProductPageDetails";
import RelatedProducts from "./RelatedProducts";
import LandingFooter from "../../../footer/LandingFooter";
import UserShoppingCartContext from "../../../../UserShoppingContext";

export default function ProductPage() {
  const urlParamId = useParams();
  const maximumCount = 30;
  const minimumCount = 1;
  const [productCount, setProductCount] = useState(1);
  const { addToCart } = useContext(UserShoppingCartContext);
  const allProducts = JSON.parse(JSON.stringify(productJson));
  const productFound = allProducts.find(
    (product) => product.id === parseInt(urlParamId.id)
  );
  let pageLocation = window.location.pathname.split("/");

  let minusProductCount = () =>
    productCount > minimumCount && setProductCount(productCount - 1);

  let plusProductCount = () =>
    productCount < maximumCount && setProductCount(productCount + 1);

  let productCountInput = (event) => {
    let prodcutUserInput = parseInt(event.target.value);

    prodcutUserInput > minimumCount && prodcutUserInput < maximumCount
      ? setProductCount(prodcutUserInput)
      : setProductCount(1);
  };
  return (
    <>
      <section>
        {!productFound ? (
          <Navigate to="/Shop/products" />
        ) : (
          <div className="pt-24 lg:pb-16">
            <div className="border-t py-8 lg:py-0 lg:pt-16">
              <div className="w-full max-w-[1140px] mx-auto">
                <div className="flex flex-col px-4 lg:flex-row items-center lg:px-3 xl:px-0 lg:justify-between">
                  <div className="lg:w-[50%]">
                    <img
                      src={productFound.img}
                      alt={productFound.title}
                      className="w-full h-full"
                    />
                  </div>
                  <div className="lg:w-[45%]">
                    <div className="pt-6">
                      <span className="text-sm block text-[#585858]">
                        <Link to="/">Home</Link> /{" "}
                        <Link to={`/${pageLocation[1]}`}>
                          {pageLocation[1]}
                        </Link>{" "}
                        /{" "}
                        <Link to={`/${pageLocation[1]}/${pageLocation[2]}`}>
                          {pageLocation[2]}
                        </Link>{" "}
                        /{" "}
                        <Link to={`/Shop/products/${productFound.id}}`}>
                          {productFound.title}
                        </Link>
                      </span>
                      <span className="py-3 block text-[#74a84a]">
                        {productFound.category}
                      </span>
                    </div>
                    <div>
                      <h1 className="text-3xl font-medium">
                        {productFound.title}
                      </h1>
                      <h2 className="font-medium text-2xl py-2">
                        ${productFound.price}
                      </h2>
                      <p className="text-[#585858]">
                        {productFound.description}
                      </p>
                    </div>
                    <div className="py-4 flex flex-wrap gap-5 border-b">
                      <div className="flex">
                        <button
                          className="text-sm w-10 h-10 border border-r-0 text-[#74a84a] flex items-center justify-center"
                          onClick={minusProductCount}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-minus" />
                        </button>
                        <input
                          type="number"
                          className="border shadow-inner smooth-transition hover:shadow-none text-center w-10 h-10 font-medium"
                          max={maximumCount}
                          min={minimumCount}
                          maxLength="2"
                          minLength="1"
                          value={productCount}
                          onInput={(event) => productCountInput(event)}
                        />
                        <button
                          className="text-sm w-10 h-10 border border-l-0 text-[#74a84a] flex items-center justify-center"
                          onClick={plusProductCount}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </button>
                      </div>
                      <div>
                        <button
                          className="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-widest h-10 px-6 md:text-lg rounded"
                          onClick={() =>
                            addToCart({ ...productFound, count: productCount })
                          }
                        >
                          ADD TO CART
                        </button>
                      </div>
                    </div>
                    <p className="text-sm py-3">
                      Category:{" "}
                      <span className="text-[#74a84a]">
                        {productFound.category}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <ProductPageDetails {...productFound} />
      <RelatedProducts category={productFound.category} />
      <LandingFooter />
    </>
  );
}
