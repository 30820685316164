import React from "react";
import ServicesHeader from "./header/ServicesHeader";
import ServicesInfo from "./services-sections/ServicesInfo";
import AboutCounseling from "../about/about-counseling/AboutCounseling";
import LandingFooter from "../../footer/LandingFooter";

export default function ServicesMain() {
  return (
    <>
      <ServicesHeader />
      <ServicesInfo />
      <AboutCounseling />
      <LandingFooter />
    </>
  );
}
