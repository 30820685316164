import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, Outlet } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import UserShoppingCartContext from "../UserShoppingContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default function RootLayout() {
  const [menuToggle, setMenuToggle] = useState(false);
  const [shoppingCartToggle, setShoppingCartToggle] = useState(false);
  const { userProductsCount, userCart, userProductsPrice } = useContext(
    UserShoppingCartContext
  );
  let toggleNavMenu = () => setMenuToggle(!menuToggle);
  let toggleShoppingCart = () => setShoppingCartToggle(!shoppingCartToggle);
  let { deleteCartProduct } = useContext(UserShoppingCartContext);

  return (
    <div className={`root-layout`}>
      <ToastContainer />
      <div
        className={`fixed w-screen h-screen bg-[#0000009e] z-20 cursor-pointer  ${
          shoppingCartToggle ? "visible" : "invisible"
        }`}
        onClick={(event) =>
          event.currentTarget === event.target && toggleShoppingCart()
        }
      >
        <div
          className={`fixed bg-[#fff] top-0 h-screen w-full max-w-[350px] smooth-transition z-20 cursor-default ${
            shoppingCartToggle ? "right-0 visible" : "right-[-500px] invisible"
          }`}
        >
          <div className="h-[8vh] flex items-center justify-between border-b">
            <h3 className="text-lg text-[#74a84a] font-medium px-4">
              Shopping Cart
            </h3>
            <FontAwesomeIcon
              icon="fa-solid fa-xmark"
              className="text-red-800 text-xl cursor-pointer px-4"
              onClick={toggleShoppingCart}
            />
          </div>
          <div className="relative w-full h-[78vh] px-4 overflow-auto">
            {userCart.length ? (
              userCart.map((item) => (
                <div key={item.id} className="flex gap-4 px-3 border-b py-3">
                  <div className="w-[120px] h-[60px]">
                    <Link to={`/Shop/products/${item.id}`}>
                      {" "}
                      <img
                        src={item.img}
                        alt={item.title}
                        className="w-full h-full"
                      />
                    </Link>
                  </div>

                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <Link to={`/Shop/products/${item.id}`}>
                        {" "}
                        <h3 className="text-lg">{item.title}</h3>
                      </Link>
                      <FontAwesomeIcon
                        icon="fa-regular fa-trash-can"
                        className="cursor-pointer text-red-400 hover:text-red-600 p-2 text-lg"
                        onClick={() => deleteCartProduct(item.id)}
                      />
                    </div>
                    <p className="text-[#585858]">
                      {item.count} x ${item.price}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="absolute left-1/4 top-2/4">
                No products in the cart.
              </h3>
            )}
          </div>
          <div className="h-[12vh] px-4 border-t">
            <div className="flex justify-between items-center py-3 text-lg font-medium">
              <h2>Total Price:</h2>
              <span>${userProductsPrice}</span>
            </div>
            <button className="text-white bg-[#74a84a] w-full hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-4 md:text-lg rounded">
              CONTINUE SHOPPING
            </button>
          </div>
        </div>
      </div>
      <div className="container max-w-[1220px] absolute left-1/2 translate-x-[-50%] flex justify-between p-5 lg:p-7 items-center">
        <div>
          <img src="../../img/logo.png" alt="main logo" />
        </div>
        <div className="flex items-center flex-row-reverse gap-3">
          <div
            className="bg-[#74a84a] w-[40px] h-[40px] flex justify-center items-center cursor-pointer md:hidden"
            onClick={toggleNavMenu}
          >
            <FontAwesomeIcon
              icon={`fa-solid ${menuToggle ? "fa-xmark" : "fa-bars"}`}
              className="text-2xl text-white"
            />
          </div>
          <div className="flex flex-row-reverse">
            <div
              className="smooth-transition flex items-center justify-center hover:text-[#74a84a] relative cursor-pointer"
              onClick={toggleShoppingCart}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-cart-shopping"
                className="px-5 text-2xl"
              />
              <span className="absolute top-[-13px] right-[5px] md:top-[-5px] text-sm text-white font-medium bg-[#74a84a] w-5 h-5 text-center rounded-full">
                {userProductsCount}
              </span>
            </div>
            <nav
              className={`${
                menuToggle ? "absolute" : "hidden"
              } absolute left-0 bg-white w-full top-20 md:flex md:static md:bg-inherit`}
            >
              <ul className="md:flex text-lg">
                <li
                  id="sidebar"
                  className="border-b smooth-transition hover:bg-[#74a84aba] hover:text-white md:hover:text-[#74a84a] md:hover:bg-inherit md:border-0	"
                  onClick={() => setMenuToggle(false)}
                >
                  <NavLink to="/" className="block px-5 py-2">
                    Home
                  </NavLink>
                </li>
                <li
                  id="sidebar"
                  className="border-b smooth-transition hover:bg-[#74a84aba] hover:text-white md:hover:text-[#74a84a] md:hover:bg-inherit md:border-0	"
                  onClick={() => setMenuToggle(false)}
                >
                  <NavLink to="/about" className="block px-5 py-2">
                    About
                  </NavLink>
                </li>
                <li
                  id="sidebar"
                  className="border-b smooth-transition hover:bg-[#74a84aba] hover:text-white md:hover:text-[#74a84a] md:hover:bg-inherit md:border-0	"
                  onClick={() => setMenuToggle(false)}
                >
                  <NavLink to="/Shop" className="block px-5 py-2">
                    Shop
                  </NavLink>
                </li>
                <li
                  id="sidebar"
                  className="border-b smooth-transition hover:bg-[#74a84aba] hover:text-white md:hover:text-[#74a84a] md:hover:bg-inherit md:border-0	"
                  onClick={() => setMenuToggle(false)}
                >
                  <NavLink to="/services" className="block px-5 py-2">
                    Services
                  </NavLink>
                </li>
                <li
                  id="sidebar"
                  className="smooth-transition hover:bg-[#74a84aba] hover:text-white md:hover:text-[#74a84a] md:hover:bg-inherit "
                  onClick={() => setMenuToggle(false)}
                >
                  <NavLink to="/contact" className="block px-5 py-2">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <main>
        <Outlet />
        <ScrollToTop />
      </main>
    </div>
  );
}
