import React from "react";
import { Link } from "react-router-dom";

export default function LandingFooter() {
  return (
    <footer className="max-w-[1140px] px-3 flex flex-col justify-center items-center pb-10 md:flex-row md:justify-between mx-auto">
      <div>
        <ul className="text-center flex flex-col sm:flex-row sm:gap-8">
          <li className="my-1">
            <Link to="/" className="py-1">
              Home
            </Link>
          </li>
          <li className="my-1">
            <Link to="/about" className="py-1">
              About
            </Link>
          </li>
          <li className="my-1">
            <Link to="/services" className="py-1">
              Services
            </Link>
          </li>
          <li className="my-1">
            <Link to="/contact" className="py-1">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="py-10 md:py-0">
        <img src="../../img/logo.png" alt="footer logo" />
      </div>
      <div>
        Rewriting By{" "}
        <a
          href="https://github.com/LeaReXx"
          className="smooth-transition hover:text-[#909] font-medium"
        >
          Sepehr Aghapour
        </a>
      </div>
    </footer>
  );
}
