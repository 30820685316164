import React from "react";

export default function ServicesInfo() {
  return (
    <section className="max-w-[1140px] mx-auto my-20">
      <div className="px-3 flex flex-col-reverse md:flex-row items-center md:gap-10 lg:gap-20 mb-20">
        <div className="basis-2/4">
          <h2 className="tracking-wide text-2xl md:text-3xl font-medium py-5">
            Web Design
          </h2>
          <p className="text-[#585858]">
            Hello, my name is Tyler Moore and with the help of many people I
            made this template. I made it so it is super easy to update and so
            that it flows perfectly with my tutorials. Lots of love and hundreds
            of hours went into making it. I hope you love it as much as I do.
          </p>
          <button class="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-8 mt-6 md:text-lg">
            EXPLORE
          </button>
        </div>
        <div className="py-6 basis-2/4">
          <img
            src="./img/pages/services-section-img (1).jpg"
            className="rounded"
            alt="Web Design"
          />
        </div>
      </div>
      <div className="px-3 flex flex-col md:flex-row items-center md:gap-10 lg:gap-20 mb-20">
        <div className="py-6 basis-2/4">
          <img
            src="./img/pages/services-section-img (2).jpg"
            className="rounded"
            alt="Graphic Design"
          />
        </div>
        <div className="basis-2/4">
          <h2 className="tracking-wide text-2xl md:text-3xl font-medium py-5">
            Graphic Design
          </h2>
          <p className="text-[#585858]">
            We specialize in logos, merchandise and more. Anyone can create nice
            graphics but it’s much better to create memorable ones. Contact us
            by calling, emailing or filling out our form. You will receive a
            free quote by email within 24 hours.
          </p>
          <button class="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-8 mt-6 md:text-lg">
            EXPLORE
          </button>
        </div>
      </div>
      <div className="px-3 flex flex-col-reverse md:flex-row items-center md:gap-10 lg:gap-20 mb-20">
        <div className="basis-2/4">
          <h2 className="tracking-wide text-2xl md:text-3xl font-medium py-5">
            Content Writing
          </h2>
          <p className="text-[#585858]">
            Want to attract people to your website? You have to have the best
            content in the world. Our content writers will create award willing
            content for you. Contact us by calling, emailing or filling out our
            form. You will receive a free quote by email within 24 hours.
          </p>
          <button class="text-white bg-[#74a84a] hover:bg-[#2c541d] smooth-transition tracking-wide py-2 px-8 mt-6 md:text-lg">
            EXPLORE
          </button>
        </div>
        <div className="py-6 basis-2/4">
          <img
            src="./img/pages/services-section-img (3).jpg"
            className="rounded"
            alt="Content Writing"
          />
        </div>
      </div>
    </section>
  );
}
