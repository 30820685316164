import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ShopPromotes() {
  return (
    <section className="border-b mb-10">
      <div className="max-w-[1140px] mx-auto my-10 md:my-16 px-3 text-center md:text-left flex flex-col gap-14 md:flex-row justify-center md:gap-0">
        <div className="border-b pb-14 md:border-r md:border-b-0 md:py-0 md:px-5 lg:px-10 md:flex md:gap-4">
          <div className="w-10 h-10 bg-[#2c541d] rounded-full text-white flex items-center justify-center mx-auto">
            <FontAwesomeIcon icon="fa-solid fa-lock" />
          </div>
          <div>
            <h3 className="tracking-wide font-medium pb-1">SECURE PAYMENT</h3>
            <span className="text-sm text-[#585858]">
              All our payments our SSL secured
            </span>
          </div>
        </div>
        <div className="border-b pb-14 md:border-r md:border-b-0 md:py-0 md:px-5 lg:px-10 md:flex md:gap-4">
          <div className="w-10 h-10 bg-[#2c541d] rounded-full text-white flex items-center justify-center mx-auto">
            <FontAwesomeIcon icon="fa-solid fa-box" />
          </div>
          <div>
            <h3 className="tracking-wide font-medium pb-1">
              DELIVERED WITH CARE
            </h3>
            <span className="text-sm text-[#585858]">
              All our payments our SSL secured
            </span>
          </div>
        </div>
        <div className=" md:px-5 lg:px-10 md:flex md:gap-4">
          <div className="w-10 h-10 bg-[#2c541d] rounded-full text-white flex items-center justify-center mx-auto">
            <FontAwesomeIcon icon="fa-solid fa-hand-holding-heart" />
          </div>
          <div>
            <h3 className="tracking-wide font-medium pb-1">
              EXCELLENT SERVICE
            </h3>
            <span className="text-sm text-[#585858]">
              All our payments our SSL secured
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
