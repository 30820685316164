import React from "react";
import ContactHeader from "./header/ContactHeader";
import ContactForm from "./contact-form/ContactForm";
import ContactInfo from "./contact-info/ContactInfo";
import LandingFooter from "../../footer/LandingFooter";

export default function ContactMain() {
  return (
    <>
      <ContactHeader />
      <section className="py-14 md:py-20 bg-[#FCFCFC]">
        <div className="max-w-[1140px] mx-auto md:flex md:justify-center gap-10">
          <ContactForm />
          <ContactInfo />
        </div>
      </section>
      <LandingFooter />
    </>
  );
}
