import React from "react";

export default function ServicesHeader() {
  return (
    <section className="bg-services-page-banner w-full bg-cover bg-center py-36 md:py-56 lg:py-64 text-center">
      <h1 className="text-4xl md:text-5xl lg:text-7xl text-[#2c541d] font-medium tracking-widest	">
      SERVICES
      </h1>
    </section>
  );
}
